import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_5j40ijIZYu from "/vercel/path0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_VdOeuw25Mb from "/vercel/path0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_hwlJ5PmHMh from "/vercel/path0/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import cookieinformation_plugin_pvQw2luVql from "/vercel/path0/node_modules/@weareheavy/nuxt-cookie-consent/dist/runtime/plugins/cookieinformation.plugin.mjs";
import plugin_Jozdw60ZsE from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import slideovers_X7whXyrIWR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import storyblok_J8xNkQ5W7C from "/vercel/path0/plugins/storyblok.js";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/vercel/path0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_5j40ijIZYu,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_tbFNToZNim,
  plugin_hwlJ5PmHMh,
  cookieinformation_plugin_pvQw2luVql,
  plugin_Jozdw60ZsE,
  plugin_ghbUAjaD3n,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  storyblok_J8xNkQ5W7C,
  plugin_auto_pageviews_client_x3Y8XIaaPY
]