import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBreadcrumbs, LazyContactForm, LazyLanguageSwitcher, LazyPriceTables, LazyStoryblokButton, LazyVideoTextOverlay, LazyAccordion, LazyBenefits, LazyBenefitsNumbers, LazyBreadcrumbsList, LazyCarousel, LazyClientQuote, LazyClientVoices, LazyClients, LazyConnectorPage, LazyContactImageList, LazyContactIntro, LazyContactSection, LazyCrewList, LazyCrewMember, LazyCrewMembersCollage, LazyCrewTalk, LazyCtaBanner, LazyDefaultPage, LazyGridImages, LazyHeadlineTextLink, LazyHubspotForm, LazyImage, LazyImageCollage, LazyInsightPage, LazyInsightsCollection, LazyInsightsDescription, LazyInsightsImage, LazyInsightsList, LazyInsightsText, LazyInsightsTextImage, LazyIntroLandingpage, LazyIntroVideo, LazyLinkList, LazyOurProjects, LazyPartner, LazyPartnerDay2024, LazyProfileButton, LazyProject, LazyProjectBlok, LazyProjectsList, LazyRichText, LazySalesCtaCard, LazySbBrandLogos, LazyServicesList, LazyStepper, LazyStickyTabs, LazyTable, LazyTeaser, LazyText, LazyTextImage, LazyTextSection, LazyVideo, LazyYouChoose, LazyIconArrowDown, LazyIconArrowRight, LazyIconArrowUp, LazyIconBehance, LazyIconButtonLink, LazyIconChevronDown, LazyIconChevronDropdown, LazyIconChevronLeft, LazyIconChevronRight, LazyIconChevronUp, LazyIconClose, LazyIconDribbble, LazyIconExperts, LazyIconFacebook, LazyIconFilter, LazyIconLinkBold, LazyIconInstagram, LazyIconLink, LazyIconLinkedin, LazyIconLoader, LazyIconLogoLabel, LazyIconLogo, LazyIconMenu, LazyIconMinus, LazyIconPause, LazyIconPlay, LazyIconPlus, LazyIconProjects, LazyIconSoundcloud, LazyIconSpotify, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Breadcrumbs", LazyBreadcrumbs],
["ContactForm", LazyContactForm],
["LanguageSwitcher", LazyLanguageSwitcher],
["PriceTables", LazyPriceTables],
["StoryblokButton", LazyStoryblokButton],
["VideoTextOverlay", LazyVideoTextOverlay],
["Accordion", LazyAccordion],
["Benefits", LazyBenefits],
["BenefitsNumbers", LazyBenefitsNumbers],
["BreadcrumbsList", LazyBreadcrumbsList],
["Carousel", LazyCarousel],
["ClientQuote", LazyClientQuote],
["ClientVoices", LazyClientVoices],
["Clients", LazyClients],
["ConnectorPage", LazyConnectorPage],
["ContactImageList", LazyContactImageList],
["ContactIntro", LazyContactIntro],
["ContactSection", LazyContactSection],
["CrewList", LazyCrewList],
["CrewMember", LazyCrewMember],
["CrewMembersCollage", LazyCrewMembersCollage],
["CrewTalk", LazyCrewTalk],
["CtaBanner", LazyCtaBanner],
["DefaultPage", LazyDefaultPage],
["GridImages", LazyGridImages],
["HeadlineTextLink", LazyHeadlineTextLink],
["HubspotForm", LazyHubspotForm],
["Image", LazyImage],
["ImageCollage", LazyImageCollage],
["InsightPage", LazyInsightPage],
["InsightsCollection", LazyInsightsCollection],
["InsightsDescription", LazyInsightsDescription],
["InsightsImage", LazyInsightsImage],
["InsightsList", LazyInsightsList],
["InsightsText", LazyInsightsText],
["InsightsTextImage", LazyInsightsTextImage],
["IntroLandingpage", LazyIntroLandingpage],
["IntroVideo", LazyIntroVideo],
["LinkList", LazyLinkList],
["OurProjects", LazyOurProjects],
["Partner", LazyPartner],
["PartnerDay2024", LazyPartnerDay2024],
["ProfileButton", LazyProfileButton],
["Project", LazyProject],
["ProjectBlok", LazyProjectBlok],
["ProjectsList", LazyProjectsList],
["RichText", LazyRichText],
["SalesCtaCard", LazySalesCtaCard],
["SbBrandLogos", LazySbBrandLogos],
["ServicesList", LazyServicesList],
["Stepper", LazyStepper],
["StickyTabs", LazyStickyTabs],
["Table", LazyTable],
["Teaser", LazyTeaser],
["Text", LazyText],
["TextImage", LazyTextImage],
["TextSection", LazyTextSection],
["Video", LazyVideo],
["YouChoose", LazyYouChoose],
["IconArrowDown", LazyIconArrowDown],
["IconArrowRight", LazyIconArrowRight],
["IconArrowUp", LazyIconArrowUp],
["IconBehance", LazyIconBehance],
["IconButtonLink", LazyIconButtonLink],
["IconChevronDown", LazyIconChevronDown],
["IconChevronDropdown", LazyIconChevronDropdown],
["IconChevronLeft", LazyIconChevronLeft],
["IconChevronRight", LazyIconChevronRight],
["IconChevronUp", LazyIconChevronUp],
["IconClose", LazyIconClose],
["IconDribbble", LazyIconDribbble],
["IconExperts", LazyIconExperts],
["IconFacebook", LazyIconFacebook],
["IconFilter", LazyIconFilter],
["IconLinkBold", LazyIconLinkBold],
["IconInstagram", LazyIconInstagram],
["IconLink", LazyIconLink],
["IconLinkedin", LazyIconLinkedin],
["IconLoader", LazyIconLoader],
["IconLogoLabel", LazyIconLogoLabel],
["IconLogo", LazyIconLogo],
["IconMenu", LazyIconMenu],
["IconMinus", LazyIconMinus],
["IconPause", LazyIconPause],
["IconPlay", LazyIconPlay],
["IconPlus", LazyIconPlus],
["IconProjects", LazyIconProjects],
["IconSoundcloud", LazyIconSoundcloud],
["IconSpotify", LazyIconSpotify],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
