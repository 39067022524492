export default defineAppConfig({
  ui: {
    strategy: 'override',
    grey: 'cool',
    primary: 'primary',
    button: {
      default: {
        size: 'md',
        color: 'primary',
        variant: 'solid',
      },
      base: 'w-fit flex-shrink-0 focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-20',
      rounded: 'rounded-4xl',
      padding: {
        md: 'px-4 py-[14px] lg:px-6 lg:py-4',
      },
      color: {
        primary: {
          solid:
            'focus-visible:outline-grey-dark rounded-4xl bg-black px-4 py-4 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-20 hover:disabled:bg-black lg:px-6',
          outline:
            'border border-black bg-white px-4 py-[14px] text-black shadow-sm disabled:bg-white lg:px-6 lg:py-4',
          link: 'h-fit rounded-none border-b-2 border-white bg-transparent px-0 py-3 lg:px-0 lg:py-3 text-white',
          icon: 'h-auto rounded-full bg-black px-4 py-4 lg:px-4 lg:py-4 text-white disabled:bg-black',
          clean: 'bg-transparent text-black disabled:bg-transparent px-0 py-0',
        },
        white: {
          solid: 'bg-white px-4 text-black shadow-sm disabled:bg-white lg:px-6',
          outline:
            'border h-fit border-white bg-transparent px-6 pt-[14px] pb-4 text-white shadow-sm disabled:border-black',
          icon: 'h-auto rounded-full bg-white px-4 py-4 lg:px-4 lg:py-4 text-black disabled:bg-white',
          link: 'h-auto rounded-none bg-transparent px-0 lg:px-0 py-3 lg:py-3 text-white',
        },
        black: {
          solid: 'shadow-sm text-white bg-black disabled:bg-black',
          outline: 'border border-white bg-black px-4 text-white lg:px-6',
          link: 'h-auto rounded-none bg-transparent px-0 lg:px-0 py-3 lg:py-3 text-black',
        },
        grey: {
          solid: 'bg-grey-darker',
        },
      },
      size: {
        md: 'text-md',
        lg: 'text-intro',
      },
    },
    accordion: {
      wrapper: 'w-full flex flex-col',
      container: 'w-full flex flex-col border-b border-grey-darker',
      item: {
        base: '',
        size: '',
        color: 'text-black',
        padding: 'pb-10',
        icon: 'ms-auto transform transition-transform duration-200 flex-shrink-0 h-6 w-6 lg:w-10 lg:h-10',
      },
      default: {
        openIcon: 'i-heroicons-plus',
        closeIcon: 'i-heroicons-minus',
        class: 'mb-0 text-intro w-full py-4 lg:py-6',
        variant: 'clean',
        color: 'primary',
        size: 'lg',
        truncate: true,
      },
    },
    checkbox: {
      wrapper: 'relative flex items-start',
      container: 'flex items-center h-5',
      base: 'h-5 w-5 mt-1 disabled:opacity-50 disabled:cursor-not-allowed focus:ring-0 focus:ring-transparent focus:ring-offset-transparent',
      form: 'form-checkbox',
      rounded: 'rounded-full',
      color: 'text-{color} border border-{color}',
      background: 'bg-transparent',
      border: '',
      ring: 'focus-visible:ring-2 focus-visible:ring-{color} focus-visible:ring-offset-2 focus-visible:ring-offset-{color}',
      inner: 'ms-3 flex flex-col',
      label: 'font-normal',
      required: 'text-sm text-red-500',
      help: 'text-sm text-gray-500',
      default: {
        color: 'white',
      },
    },
    modal: {
      padding: '',
      margin: 'lg:my-30',
      overlay: {
        background: 'bg-[#3B3B3B]/80',
      },
      background: 'bg-white',
      ring: '',
      rounded: '',
      shadow: '',
      width: 'w-full max-w-[1200px]',
    },
  },
})
